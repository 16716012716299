import styled from '@emotion/styled';
import { COLORS, BREAKPOINTS } from './variables';
import { Link } from 'gatsby';

export const TitleExtraLarge = styled.h1`
  color: ${COLORS.BLACK};
  font-family: Adonis !important;
  font-size: 88px;
  line-height: 92px;
  font-weight: 300;
  letter-spacing: -1.5px;
  margin-bottom: 32px;

  @media ${BREAKPOINTS.MOBILE} {
    font-size: 52px;
    line-height: 54px;
    margin-bottom: 24px;
  }
`;

export const TitleMediumSerif = styled.h2`
  font-family: Adonis !important;
  color: ${COLORS.BLACK};
  font-size: 52px;
  line-height: 56px;
  font-weight: 400;
  letter-spacing: -1.5px;

  @media ${BREAKPOINTS.MOBILE} {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -1px;
  }
`;

export const TitleMediumSemibold = styled.h2`
  color: ${COLORS.BLACK};
  font-size: 44px;
  line-height: 64px;
  font-weight: 700;
  letter-spacing: 0.5px;

  @media ${BREAKPOINTS.TABLET} {
    font-size: 32px;
    line-height: 48px;
  }
`;

export const TitleMedium = styled.h2`
  color: ${COLORS.BLACK};
  font-size: 44px;
  line-height: 64px;
  font-weight: 300;
  letter-spacing: 0.5px;

  @media ${BREAKPOINTS.TABLET} {
    font-size: 32px;
    line-height: 48px;
  }
`;

export const TitleSmallBold = styled.h4`
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0px;

  @media ${BREAKPOINTS.TABLET} {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const Subtitle = styled.p`
  color: ${COLORS.BLACK};
  font-size: 22px;
  line-height: 30px;
  font-weight: 300;
  letter-spacing: 0.5px;

  @media ${BREAKPOINTS.TABLET} {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const SubtitleSemibold = styled.p`
  color: ${COLORS.BLACK};
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 0.5px;

  @media ${BREAKPOINTS.TABLET} {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const BodyTiny = styled.p`
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
`;

export const Uppercase = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 5px;
  text-transform: uppercase;

  @media ${BREAKPOINTS.TABLET} {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const Topic = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.5px;
`;

export const Numbers = styled.span`
  font-family: Adonis !important;
  font-weight: 400;
  font-size: 48px;
  line-height: 52px;
  letter-spacing: -2px;

  @media ${BREAKPOINTS.TABLET} {
    font-size: 40px;
    line-height: 48px;
  }
`;

export const Name = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 5px;
  text-transform: uppercase;

  @media ${BREAKPOINTS.MOBILE} {
    font-size: 12px;
  }
`;

export const LocationLink = styled(Link)`
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0px;
  color: ${COLORS.BLACK};
  display: flex;
  justify-content: space-between;

  @media ${BREAKPOINTS.MOBILE} {
    font-size: 20px;
    line-height: 28px;
  }
`;

const Links = styled.a`
  font-weight: 600;
  letter-spacing: 0.5px;
`;

const NavLarge = styled.span`
  font-size: 18px;
  font-weight: 300;
  line-height: 120%;
  letter-spacing: 0.5px;
`;
const NavMedium = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.5px;
`;
const NavSmall = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.5px;
`;
